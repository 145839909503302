.technical-title{
    h2.vc_custom_heading.custom-title{

        padding-left: 90px;
        &:before{
            content: "";
            background: url(https://project.inspiren.com.my/pioneer/wp-content/uploads/2019/08/b-msdn.jpg) no-repeat;
            -webkit-background-size: contain;
            -moz-background-size: contain;
            background-size: contain;
            width: 737px;
            height: 24px;
            position: absolute;
            left: -430px;
            top: 20px;
        }
    }
}

.technical1{
    overflow: visible !important;

    .product-desc{

        @media only (--not_phone){
            padding-left: 10%;
        }
    }

    .arrow-down.arrow-down-left{
        &:before{
            top: -80px;
        }

        &:after{
            top: -140px;
        }
    }
}

.technical2{
    .product-desc{
        @media only (--not_phone){
            padding-top: 150px;
        }
    }

    .custom-btn{
        margin-top: 20px;
    }

    .arrow-down.arrow-down-right{
        &:before{
            top: 300px;
        }
        &:after{
            top: 250px;
        }
    }
}

.technical3{
    margin-top: 80px;

    .product-desc{

        @media only (--not_phone){
            padding-left: 5%;
        }
    }

    .custom-btn{
        margin-top: 20px;
    }
}

.technical4{
    margin-top: 80px;

    @media only (--portable){
        display: flex;
        flex-direction: column;

        .wpb_column:nth-child(2){
            order: 2;
        }
    }

    .custom-btn{
        margin-bottom: 20px;
    }
}

.map{
    margin-top: 50px; 

    &>.wpb_column>.vc_column-inner{

        @media only (--not_phone){
            padding-top: 100px !important;
        }
    }

    .map-item{
        padding: 20px 5px;
        width: fit-content;
        background: var(--color-blue);
        color: white;
        position: absolute;

        @media (--portable){
            position: static;
            width: 100% !important;
            margin: 0;
            margin-bottom: 10px;
        }

        .wpb_text_column.wpb_content_element{
            margin-bottom: 0;

            p{
                font-size: 14px;
                line-height: 20px;
            }
        }
        h3.vc_custom_heading{
            margin-top: 0;
        }
    }

    .map-item:nth-child(2){   
        top: 0;
        left: 50%;            
    }
    .map-item:nth-child(3){   
        top: 50%;
        left: 0;
        width: 200px;
    }
    .map-item:nth-child(4){   
        bottom: 0;
        left: 40%;
        width: 135px;
    }
    .map-item:nth-child(5){   
        top: 50%;
        right: 0;
        width: 190px;
    }

}

.bg-cover .partner-cover-title{
    padding-left: 55%;

    @media only screen and (min-width: 1351px){
        padding-left: 65%;
    }
}

.msds-row{
    margin-bottom: 20px;

    @media only (--not_phone){

    &>.wpb_column:nth-child(2){
        width: 44%;
        margin-right: -3%;
    }
    &>.wpb_column:nth-child(3){
        width: 44%;
        margin-left: -3%;
        margin-top: 60px;
    }
}

}
.video-title{
    /*@media only(--not_phone){*/
        /*position: absolute;*/
    /*}*/
}
.video-row{
    /*@media only(--not_phone){*/
        /*margin-top: 60px;*/
    /*}*/
    &>.wpb_column:first-child{
       
            /*margin-top: 100px;*/
        
    }
}


.news-sec{
    margin-bottom: 100px;

    @media (--portable){
        margin-top: 70px;

        .news-title{
            margin-bottom: 20px;
        }
    }

    .news-grid{

        .vc_grid-item{

            @media only (--not_phone){
                &:nth-child(2){
                    margin-top: 150px;
                }

                &:last-child{
                    margin-top: 300px;
                }
            }
        }
    
    .vc_gitem-zone.vc_gitem-zone-a{
        
        &:before {
            content: none;
        }
        .vc_gitem-zone-mini{

            .vc_gitem_row.vc_row{
                background: var(--color-box);
                position: static;
                transform: inherit;
            }

            .vc_col-sm-12.vc_gitem-col {

            }
            .vc_gitem-post-data-source-post_title{
                h2{
                    a{
                        font-size: 25px;
                        color: var(--color-blue)
                    }
                }
            }

            .custom-btn {
                display: none
            }

        }
    }

    .vc_gitem-zone.vc_gitem-zone-a .vc_gitem-zone-mini:hover{
        color: var(--color-font);

        h2{
            color: var(--color-blue);
        }

    }
}

}

.media-sec{

    @media (--portable){
        .news-title{
            margin-bottom: 20px;
        }
    }
    .news-grid .vc_grid-item.vc_col-sm-6:last-child{
        margin-top:0;
    }
    .vc_grid-item{
        @media only (--not_phone){
            &:nth-child(2){
                margin-top: 120px;
            }
            &:nth-child(2n+1){
                margin-top: -120px;
            }
            &:first-child{
                margin-top: 0;    
            }
        }
    }
}

.modal-popup-box button.model-popup-btn{
    border: none;
    font-size: 16px;
    padding: 0 0!important;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Cabin';
    background: none !important;
    position: relative;

    &:after{
        content: "";
        width: 100%;
        height: 1px;
        background: #00966f;
        bottom: 0;
        position: absolute;
        left: 15px;
    }
}

.mega-model-popup.Default{
/*    background: none !important;
    border: none;
    box-shadow: none;*/

    h2{
        border-bottom: none !important;
        margin-top: 30px !important;
    }

    span.b-close img{
        max-width: 20px;
    }

    a.download{
        background-color: var(--color-green);
         padding: 10px 40px; 
        color: white;
        margin-top: 30px;
        display: inline-block;
    }
} 

a.to-form {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-size: 0;
}


.dl-btn .wpb_column.vc_column_container:last-child,
.login-btn .wpb_column.vc_column_container:last-child{
    padding-top: 10px;
}