#sidebar-footer.contact-detail{
	display: block;

	@media (--portable){
		margin-top: 20px;
	}

	.sidebar-column:last-child{
		margin-top: 0 !important;
	}

	.sidebar-column{
		&>.vc_column-inner{
			@media (--portable){
				padding-top: 0 !important;
			}
		}
	}

}

.contact-form{
	.contact-split{
		@media (--not_phone){
			.vc_col-sm-6:first-child{
				padding-left: 0;
			}
			.vc_col-sm-6:last-child{
				padding-right: 0;
			}
		}

		.vc_col-sm-6{
			@media(--portable){
				padding: 0 !important;
			}
		}
	}
	
	input.wpcf7-form-control.wpcf7-text{
		color: var(--color-font);
		background: white;
		border: 1px solid var(--color-font);
		margin-bottom: 15px;
		font-size: 16px;
		padding-left: 25px;

		&::-webkit-input-placeholder{
			color: var(--color-font) !important;
		}
	}
	textarea.wpcf7-form-control.wpcf7-textarea{
		background: white;
		height: 40px;
		border: 1px solid var(--color-font);
		padding-left: 25px;
		font-size: 16px;
		margin-bottom: 15px;
		color: var(--color-font);
		padding-top: 7px;

		&::-webkit-input-placeholder{
			color: var(--color-font) !important;
		}
	}
	select.wpcf7-form-control.wpcf7-select{
		-webkit-appearance: none;
		/*background: white;*/
		height: 40px;
		font-size: 16px;
		margin-bottom: 15px;
		color: var(--color-font);
		border-color: var(--color-font);
		width: 100%;
		background: url(https://project.inspiren.com.my/pioneer/wp-content/uploads/2019/08/select-arrow1.png);
		    background-position-x: right;
		    background-repeat: no-repeat;
		    background-size: contain;
	}
	input[type="submit"]{
		background: var(--color-green);
		color: white;
		text-transform: uppercase;
		float: none;
	}
	input[type="reset"]{
		background: var(--btn-grey);
		color: white;
		text-transform: uppercase;
		border-color: var(--btn-grey);
		float: none;
	}
	.contact-btn{
		text-align: center;
	}
}


#sidebar-footer.contact-detail .sidebar-column:first-child:before{
	content: none;
}

.file-manager{
	display: none;
}
.logged-in .file-manager{
	display: block;
}

.vacancy{

	ul{
		margin-top: -20px;
		margin-bottom: 20px;
	}

	.modal-popup-box button.model-popup-btn{
		background: #00966f !important;
		color: white !important;
		padding: 5px 20px !important;

		&:after{
			content: none;
		}
	}
}