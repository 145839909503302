   /* ####################################################################################

                                    OVERALL

/* ####################################################################################*/

#content .container.content-wrapper{
	padding-top: 0;
}
#mainnav ul li ul:after{
	left: 5px !important;
}

header#masthead{
/*	padding: 5px;
	background-color: rgba(51,51,51,0.6);
	position: fixed !important;*/

	li.menu-item{
		a{
			font-size: 16px;
		}
		@media (--desktop){
			padding: 0 2%;	
		}
	}
	.current-menu-parent>a,
	li.current-menu-item>a{
		color: var(--color-green);
		font-weight: 600;
	}

	.site-logo{
		max-width: 250px;
	}

	@media (--desktop){
		.col-md-4{
			width: 25%;
		}
		.col-md-8{
			width: 75%;
		}
	}
}

nav#mainnav{
	width: 100%;
}

body p{
	line-height: 26px;
	font-weight: 400 !important;
}


#sidebar-footer{
	padding: 20px 0 0px 0;

	@media (--not_desktop){
		padding: 30px 0 0px
	}

.sidebar-column {

	&>.vc_column-inner{
		width: fit-content;
		margin: auto;
	}

	@media (--not_desktop){
		text-align: center;
		}
	

	&:first-child{
		@media only screen and (min-width: 768px) {
			&:before{
			    content: '';
			    background: url(https://project.inspiren.com.my/pioneer/wp-content/uploads/2019/08/b-footer.jpg)no-repeat;
			    position: absolute;
			    width: 500px;
			    height: 58px;
			    background-position: center;
			    background-size: contain;
			    left: -510px;
			    position: absolute;
			}
		}

		img{
			max-width: 300px !important;
		}
    }

    &:nth-child(2){

    	.widget_text{
    		margin-bottom: 10px;

    		p{
    			margin-bottom: 0;
    		}
    	}
		h2{
			margin-top: 0;
			font-size: 22px;
			font-weight: 600;
			margin-bottom: 10px;

			.comp-num{
				font-size: 11px;
			}
		}

    }
    &:last-child{
    	@media (--not_desktop){
    		margin-top: 40px;
    	}

    	aside#custom_html-2{
    		padding-top: 0;

		    a{
		    	height: 25px;
			    width: 25px;
			    display: inline-flex;
			    align-items: center;
			    justify-content: center;
			    background: var(--color-green);
			    border-radius: 50%;
			    color: white;
			    margin-right: 6px;
			}
    	}
    	aside#text-3 {
		    margin-bottom: 0;
		    margin-bottom: -10px;


		    
		}
    }
}
}

.content-area .hentry:first-child {
    padding: 0;
}


footer#colophon{
	/*background: rgba(51,51,51,0.3);*/

	.site-info.container{
		/*text-align: center;*/
		padding: 5px 0;

		&>* {
		    display: inline-block;
		    float: none;
		    font-size: 14px;
		    margin-bottom: 0;

		    @media (--portable){
		    	display: block;
		    	text-align: center;
		    }

		}
	}
}

@media (--not_phone){
a.hoverInspiren{
	float: none;
}
}

#sidebar-footer{
	display: none;
}

.home #sidebar-footer{
	display: block;
}

footer#colophon .site-info.container {
    border-top: 1px solid;

    @media (--not-phone){
	    padding-left: 50px;
	}
}

.home footer#colophon .site-info.container{
	border: none;
}

#content{
	@media (--phone){
		overflow: hidden;
	}
}

/*=====bottom contact button======*/
a.floating-button {
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 50px;
    bottom: 80px;
    right: 30px;
    font-size: 20px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    z-index: 1000;
    animation: pulsess 3s infinite;
}
a.floating-button_email {
    bottom: 150px;
    background-color: #0078FF;
}
a.floating-button_phone {
    bottom: 220px;
    background-color: rgba(220, 16, 16, .75);
}
a.wechat-pop{
	bottom: 290px;
}
@keyframes pulsess {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, .3);
  }
  30% {
    box-shadow: 0 0 0 5px rgba(0,0,0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
  80% {
    box-shadow: 0 0 0 5px rgba(0,0,0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}

.account{
	position: absolute;
	right: 0;
	top: -30px;
}

    @media only (--desktop){
header#masthead li.menu-item .sub-menu {
		left:50%;
		transform: translateX(-50%);
		width: 200px;
li a {
    line-height: 20px;
		width: 200px;
}
}

header#masthead li.menu-item.menu-item-268 .sub-menu{
	width: 150px;

	li a{
		width: 150px;
	}
} 

header#masthead li.menu-item.menu-item-269 .sub-menu{
	width: 130px;

	li a{
		width: 130px;
	}
} 
}