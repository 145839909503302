   /* ####################################################################################

                                    HOME

/* ####################################################################################*/
.home-slider{
	margin-bottom: 50px;
	.wpb_revslider_element.wpb_content_element {
	    margin: 0;
	}

	div#slider-2-layer-3{
		strong{
			font-weight: 700 !important;
			font-size: 1em !important;
		}
	}
	
	@media only (--portable){
		div#slider-2-layer-2{
			background: none !important;
		}
		div#slider-2-layer-1{
			opacity: 0.8 !important;
		}
	}
}

.wpb_single_image.wpb_content_element.title-bar{
	margin-bottom: 10px;

	@media(--portable){
		text-align: center;
	}

	img{
		max-width: 350px;
	}
}
.home-about{

	@media (--not_phone){
	.wpb_column:first-child{
		padding-right: 20px;
	}
	.wpb_column:last-child {
	    padding-left: 20px;

	    .wpb_single_image{
	    	margin-bottom: 0;
	    	padding-top: 40px;

	    	.vc_figure{
	    		max-width: 400px;
	    		position: relative;

	    		&:before{
	    			content: '';
	    			background: var(--color-blue);
	    			width: 30%;
	    			height: 80%;
	    			position: absolute;
	    			right: -30px;
	    			top: -30px;
	    		}
	    		.vc_single_image-wrapper{
	    			position: relative;
	    		}
	    	}
	    }
	}
	h2.custom-title {
		    margin-top: 0px;
		    margin-bottom: 10px;
	}
}
}

.custom-btn {
	position: relative;

	&:after{
		content:'';
		width: 100%;
		height: 1px;
		background: var(--color-green);
		bottom: 0;
		position: absolute;
		left: 15px;

		
	}
	a.vc_general {
	    /*padding: 0px 18px 0px 5px !important;*/
	    border: none;
	    font-size: 16px;
	    padding: 0 0 5px 0 !important;
	    font-weight: 400;

	    &:hover{
			border: none !important;
		}
	}
}

h2.vc_custom_heading.custom-title{
	position: relative;
	padding: 10px 0;
}

.process-sec{
	margin-top: 80px;

	@media (--portable){
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.step-row{
		&>.wpb_column>.vc_column-inner>.wpb_wrapper{
			text-align: center;
			
			&>div{
				background: var(--color-box);
				padding: 20px 0;

				@media (--portable){
					width: 48%;
					margin: 1%;
					display: inline-block;
				}
			}
		    
		    @media only screen and (min-width: 768px){
				display: grid;
				grid-template: "a a . . . . . ." 
							   "a a b b . . . ." 
							   "e e b b c c . ." 
							   "e e f f c c d d"
							   ". . f f g g d d" 
							   ". . i i g g h h"
							   ". . i i j j h h"
							   ". . . . j j . ."
							   / auto;
				grid-gap: 20px 50px;

				&>div:first-child{
					grid-area: a;
				}
				&>div:nth-child(2){
					grid-area: b;
				}
				&>div:nth-child(3){
					grid-area: c;
				}
				&>div:nth-child(4){
					grid-area: d;
				}
				&>div:nth-child(5){
					grid-area: e;
				}
				&>div:nth-child(6){
					grid-area: f;
				}
				&>div:nth-child(7){
					grid-area: g;
				}
				&>div:nth-child(8){
					grid-area: h;
				}
				&>div:nth-child(9){
					grid-area: i;
				}
				&>div:nth-child(10){
					grid-area: j;
				}
			}
			
			h5{
				margin-bottom: 0;
			}
			h6{
				margin-bottom: 0;
				margin-top: 5px;
			}
			.wpb_single_image{
				margin-bottom: 0;
				img{
					max-width: 150px;
				}
			}

		}
	}
}

.arrow-left{
		@media (--not_phone){
			position: absolute;
			width: 100%;
			right: 0;
		}
		
		.wpb_column:last-child{
			@media (--not_phone){
			&:before{
				content: '';
			    position: absolute;
			    width: 190px;
			    height: 1px;
			    background: var(--color-blue);
			    left: 0;
			    top: 50%;
			    transform: translateY(-50%);
			}

			&:after{
				content: '';
				position: absolute;
				left: 0; 
				top: 50%;
			    transform: translateY(-50%);
				  width: 0; 
				  height: 0; 
				  border-top: 10px solid transparent;
				  border-bottom: 10px solid transparent; 	  
				  border-right: 20px solid var(--color-blue); 
			}
		}
			
			h2{
				margin: 0;
				/*margin-right: 5%;*/
			}
		}
	}
	h2.vc_custom_heading.custom-title{
		@media (--portable){
			text-align: center !important;
		}
	
}

.arrow-down{

	@media (--phone){
		display: none;
	}
	&:before{
		content: '';
	    position: absolute;
	    width: 1px;
	    height: 300px;
	    background: var(--color-blue);
	    right: 10px;
	    top: 30px;
	}

	&:after{
		content: '';
		position: absolute;
		right: 0; 
	    transform: translateY(-50%);
		  width: 0; 
		  height: 0;
	 border-left: 10px solid transparent;
	  border-right: 10px solid transparent;
	  border-top: 20px solid var(--color-blue);
	}
}

.home-product-sec{
	.wpb_text_column.wpb_content_element{
			margin-bottom: 5px;
	}
	h2{
		margin-bottom: 10px;
	}
	.home-product-btn{
		width: fit-content;
    	margin: auto;

    	@media (--not_phone){
    	margin-top: 50px;
    }
	}
}

.home-product1{
	.wpb_single_image{
		margin-bottom: 0;
		padding-left: 25%;

		.vc_single_image-wrapper{
			position: relative;
			left: -30px;

			&:before{
			    content: '';
			    position: absolute;
			    width: 20%;
			    height: 106%;
			    background: var(--color-blue);
			    right: -4%;
			    top: -8px;
			}

	    	img{
		    	max-height: 500px;
		    	width: auto;
		    	position: relative;
		    }
	    }
	}
	.product-desc {

		@media (--not_phone){
		    position: absolute;
		    bottom: 0;
		    left: 15%;
		}

		.wpb_text_column.wpb_content_element{
			margin-bottom: 5px;
		}
		h2{
			margin-bottom: 10px;
		}
		
	}

	.wpb_column:last-child{
		position: relative;
	}
	
}

.home-product2{
	margin-top: 170px;

	@media (--portable){
		margin-top: 80px;
	}

	.vc_single_image-wrapper{
		position: relative;

		&:before{
		    content: '';
		    width: 30%;
		    height: 116%;
		    position: absolute;
		    left: -30px;
		    top: -30px;
		    background: var(--color-blue);
		}

		img{
			position: relative;
		}
	}
}

.home-product3{
	.arrow-down{

		&:after{
			top: 50%;
			left: 0 !important;
		}

		&:before{
			left: 10px;
			top: 55%;
		}
	}

	img{
		max-height: 500px;
		width: auto;
	}
}

.home-product4{
	margin-top: 70px;
	padding-left: 100px;
	
	@media only screen and (min-width: 768px){
		margin-bottom: -300px;
	}

	@media (--portable){
		padding-left: 0;
	}

	.wpb_column:first-child{
		.wpb_single_image{
			@media only (--not_phone){
				margin-top: 100px;
			}

			.vc_single_image-wrapper{
				position: relative;

				&:before{
					content: '';
					position: absolute;
					height: 90%;
					width: 80%;
					left: -30px;
					background: var(--color-blue);
					top: 50%;
					transform: translateY(-50%);
				}

				img{
					position: relative;
					max-height: 500px;
				}
			}
		}
	}
	.wpb_column:last-child img {
		max-height: 500px;
		width: auto;
	}

}

.home-product4-img{
	@media only screen and (min-width: 768px){
		padding-left: 12%;
	.wpb_column:last-child{
		.wpb_single_image:last-child{
			position: relative;
			left: -12%;
			margin-top: 300px;
		}
	}
}
}

.news-grid{
	margin: 50px 0;

	@media (--portable){
		margin-top: 0;
	}
	.vc_grid-container {
	    margin-bottom: 0;
	}
	
	.vc_grid-item.vc_col-sm-6:last-child{
		@media only screen and (min-width: 768px){
			margin-top: 200px;
		}
	}
	.vc_gitem-zone.vc_gitem-zone-a{
		&:before {
		    content: '';
		    width: 100%;
		    height: 100%;
		    background: rgba(230,231,231,0.9);
		    position: absolute;
		    transition: 0.3 all ease-in-ease-out;
		}
		.vc_gitem-zone-mini{

			.vc_gitem_row.vc_row{
				position: static;
	    		transform: inherit;
			}

			.vc_col-sm-12.vc_gitem-col {
				position: relative;
			    z-index: 99;
			    min-height: 230px;
    			padding: 40px 80px;
			}
			.vc_gitem-post-data-source-post_title{
				margin-bottom: 5px;
				h2{
					font-size: 25px;
				}
			}
			.vc_gitem-post-data-source-post_excerpt {
				margin-bottom: 5px;
				p{
					margin-bottom: 0;
				}
			}

			.custom-btn {
				a.vc_general{
					color: var(--color-font);
				}
				&:after{
					background: var(--color-font);
				}
			}
			.vc_btn3-container{
				margin-bottom: 0;
			}

		}
	}
	img.vc_gitem-zone-img{
			display: none;
		}

	.vc_gitem-zone.vc_gitem-zone-a:hover:before{
		background: #00966F !important;
		opacity: 0.9;
		transition: 0.3 all ease-in-ease-out;
	}
	.vc_gitem-zone.vc_gitem-zone-a .vc_gitem-zone-mini:hover{
		color: white;

		h2{
			color: white;
		}

		.custom-btn:after{
			background: white !important;
		}
		a.vc_general{
			color: white !important;
		}

	}
}


.vc_row.home-enquiry{
	padding: 20px 0;

	&:before{
		content: '';
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    background: rgba(0,0,0,0.5);
	    display: block;
	    top: 0;
	    filter: blur(1px);
	}
	.vc_column-inner{
		padding-top: 0 !important;
	}
	h2{
		margin-bottom: 15px !important;
	}

	.vc_inner .wpb_column:first-child{

		@media(--not_phone){
			&:before{
				content: '';
				position: absolute;
				height: 400px;
				width: 400px;
				background: var(--color-box);
				transform: rotate(45deg) translate(-150px);
				top: -50%;
			}
			&:after{
				content: '';
				position: absolute;
				height: 400px;
				width: 46px;
				background: var(--color-green);
				transform: rotate(45deg);
				top: 20px;
				left: 198px;
			}
		}
	}
	a.vc_general.vc_btn3 {
	    padding: 5px 20px;
	    font-weight: 400;
	}
}

