.bg-cover{
	height: 30vw;

	h2{
		position: relative;
	    padding-left: 45%;
	    margin: 0;

	    @media (--phone){
	    	font-size: 30px !important;
	    }

	    @media only (--portable){
	    	width: fit-content;
	    	margin-right: 0;
	    	margin-left: auto;
	    	padding: 7px 20px !important;
	    	background: rgba(229,229,229,0.75);
	    }
	}
	.cover-edge{
		height: 800px;
	    width: 800px;
	    position: absolute;
	    top: 50%;
	    right: -20%;
	    background: var(--cover-grey);
	    transform: rotate(45deg) translateY(-50%);
	    transform-origin: top;

	    @media only screen and (min-width: 1351px){
	    	height: 1000px;
	    	width: 1000px;
	    }

	    @media only (--portable){
	    	right: -160%;
	    	background: none;
	    }
		
		&:before{
			content: '';
		    position: absolute;
		    width: 40px;
		    height: 800px;
		    background: var(--color-green);
		    top: 0;

		    @media only screen and (min-width: 1351px){
		    	height: 1000px;
		    }

		    @media only (--portable){
		    	background: none;
		    }
		}
	}
}

.about-row3,
.about-row2{

	@media only (--not_phone){
	margin-top: 130px;
}
}


.top-right{
	position: relative;
	figure{
		position: relative;
	}
	figure:before{
	    content: "";
		background: #0b195c;
		width: 30%;
		height: 80%;
		position: absolute;
		right: -20px;
		top: -20px;
	}
	.vc_single_image-wrapper{
		position: relative;
	}
}

.top-left{
	position: relative;
	figure{
		position: relative;
	}
	figure:before{
		content: "";
		background: #0b195c;
		width: 30%;
		height: 80%;
		position: absolute;
		left: -20px;
		top: -20px;
	}

	.vc_single_image-wrapper{
		position: relative;
	}
}

.bottom-right{
	position: relative;
	figure{
		position: relative;
	}
	figure:before{
		content: "";
		background: #0b195c;
		width: 30%;
		height: 80%;
		position: absolute;
		right: -20px;
		bottom: -20px;

		@media (--portable){
			right: -10px;
		}
	}

	.vc_single_image-wrapper{
		position: relative;
	}
}

.right{
	position: relative;
	figure{
		position: relative;
	}
	figure:before{
		content: '';
		background: #0b195c;
		width: 20%;
		height: 107%;
		position: absolute;
		right: -20px;
		top: -20px;

		@media (--portable){
			height: 110%;

		}
	}
	.vc_single_image-wrapper{
		position: relative;
	}
}

.right-short{
	position: relative;
	figure{
		position: relative;
	}
	figure:before{
		content: '';
		background: #0b195c;
		width: 20%;
		height: 80%;
		position: absolute;
		right: -20px;
		top: 50%;
		transform: translateY(-50%); 
	}
	.vc_single_image-wrapper{
		position: relative;
	}
}
.left{
	position: relative;
	figure{
		position: relative;
	}
	figure:before{
		content: '';
		background: #0b195c;
		width: 20%;
		height: 111%;
		position: absolute;
		left: -20px;
		top: -20px;
	}
	.vc_single_image-wrapper{
		position: relative;
	}
}

.vision-sec{
	@media (--portable){
		margin-top: 70px;
	}
}
.vision-mission{


	@media only (--not_phone){
		margin-top: 80px;
	}

	&>.wpb_column:last-child{

		@media only (--not_phone){
			margin-top: 100px;
		}
	}

	&>.wpb_column>.vc_column-inner{
		padding: 60px 40px !important;
		margin: 20px;
		/*height: 300px;*/
		position: relative;

		@media only (--portable){
			padding: 80px 40px !important;
		}

		&:before{
			content: '';
		    display: block !important;
		    height: 100%;
		    position: absolute;
		    width: 100%;
		    left: 0;
		    top: 0;
		    background: rgba(0,0,0,0.6);
		    transition: 0.3s all ease-out;
		}

		&:hover{
			&:before{
				background: rgba(0,150,111,0.8);
				transition: 0.3s all ease-out;
			}
			.wpb_single_image{
				opacity: 0;
				transition: 0.3s all ease-out;
			}
			.vm-hover{
				opacity: 1;
				transition: 0.3s all ease-out;
			}

		}
	}
	.wpb_single_image{
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 0;
		position: relative;
		transition: 0.3s all ease-out;

		h2{
			order: 2;
			color: white;
			font-size: 30px;
			margin-bottom: 0;
		}
		img{
			max-width: 100px;
		}
	}

	.vm-hover{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		padding: 40px;
		opacity: 0;
		transition: 0.3s all ease-out;

		.wpb_text_column{
			margin-bottom: 0;
			color: white;
		}
	}
}

.value-sec{
	.value-title{

		@media only (--not_phone){
			position: absolute;
			right: 0;
			width: 100%;
		}

		.wpb_single_image{
			margin-bottom: 20px;

			@media (--portable){
				text-align: center;
			}
		}

		h2{
			margin-bottom: 5px;
		}

		p{
			@media (--portable) {
				text-align: center !important;
			}
		}
	}
	.value-grid{
		.wpb_single_image{
			margin-top: 20px;
			img{
				max-width: 80px !important;
			}
		}

		&>.wpb_column>.vc_column-inner>.wpb_wrapper>div{

			@media (--portable){
				height: 220px;

				h6{
					height: 80px;
				}
			}
		}
	}
}

.team-row{
	margin-top: 40px;

	h2.vc_custom_heading.custom-title{
		margin-top: 30px;
		margin-bottom: 5px;
	}

	.wpb_column:last-child>.vc_column-inner{
		@media(--portable){
			padding-top: 0 !important;
		}
	}
}

.team-row2{
	margin-top: 50px;

	h2.vc_custom_heading.custom-title{
		margin-top: 0;
	}
	.wpb_text_column.wpb_content_element{

		@media only (--not_phone){
			margin-top: 60px;
    		padding-right: 30px;
		}

		@media (--portable){
			p{
				text-align: center !important;
			}
		}
		
	}
	
	@media (--portable){
		display: flex;
		flex-direction: column;

		.wpb_column:first-child{
			order: 2;
		}
	}
}

.milestone-row{
	.vc_column-inner{
		padding: 0 !important;
	}
}

.manage-row{
	margin-top: 60px;
	
	@media only (--not_phone){
		.wpb_column:last-child{
			padding-left: 50px;
		}
	}

	h2.vc_custom_heading.custom-title{
		margin-top: 30px;
		margin-bottom: 20px;
	}
}


.arrow-down{
	&:before{
		content: '';
	    position: absolute;
	    width: 1px;
	    height: 300px;
	    background: var(--color-blue);
	    right: 10px;
	    top: 30px;
	}

	&:after{
		content: '';
		position: absolute;
		right: 0; 
	    transform: translateY(-50%);
		  width: 0; 
		  height: 0;
	 border-left: 10px solid transparent;
	  border-right: 10px solid transparent;
	  border-top: 20px solid var(--color-blue);
	}
}

.arrow-down.arrow-down-left{
	&:before{
		left: 10px;
		top: 80px;
	}

	&:after{
		left: 0px;
}
}

.arrow-down.arrow-down-right{
	&:before{
		right: 10px;
		top: 80px;
	}

	&:after{
		right: 0px;
}
}

.cert-row{
	margin-top: 50px;
}

.cert-row2{
	.wpb_column:nth-child(2){
		&>.vc_column-inner{
			position: absolute;
			top: -185px;
		}
	}

	.wpb_text_column.wpb_content_element {
	    width: 50%;
	    margin-right: 0;
	    margin-left: auto;
	    margin-top: 30px;
	}
}

.cert-row3{
	.arrow-down.arrow-down-right:after{
		top: 30px;
	}
	.wpb_column:nth-child(2){
		padding-left: 30px;
	}

}

.cert-row4{
	h2.vc_custom_heading.custom-title{
		&:before{
		    content: '';
		    background: url(https://project.inspiren.com.my/pioneer/wp-content/uploads/2019/08/b-award.jpg) no-repeat;
		    background-size: contain;
		    width: 737px;
		    height: 24px;
		    position: absolute;
		    left: -103%;
			top: 15px;
		}
	}
}


.policy-point ul {
    padding-left: 20px;
}

.cert-row,
.cert-row2,
.cert-row3,
.cert-row4{
	.wpb_text_column.wpb_content_element{
		margin-bottom: 0;
	}
}