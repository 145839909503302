.product-sec{
	margin-top: 50px;
	
	@media only (--not_phone){
		.product1-img{
			margin-bottom: -300px;
			.wpb_single_image.vc_align_center{
				padding-left: 20%;

				img{
					max-height: 500px;
					width: auto;
				}
			}
		}
	}
}

.product1{
	@media only (--not_phone){
		margin-top: 70px;
	}
	position: relative;
	padding-left: 5%;
	left: 0;

	.arrow-down.arrow-down-right{
		margin-bottom: 35px;
	}

	.wpb_column:first-child{

		@media only (--not_phone){
			padding-left: 10%;
		}
	}

	.desc1{
		max-width: 400px;
	}

	.desc2{
		p{
			column-count: 2;
		}
	}

	h2.vc_custom_heading.custom-title3 {
	    margin-top: 30px;
	}

	img{
		smax-height: 500px;
	    width: auto;
	    padding-left: 30%;
	}

}

.product2{
	margin-top: 70px;

	.desc2{
		column-count: 2;
	}
	h2.vc_custom_heading.custom-title3 {
	    margin-top: 30px;
	}
	.wpb_column:last-child{

		@media only (--not_phone){
			padding-top: 120px;
		}
	}
}

.product3{
	margin-top: 70px;

	@media only (--portable){
		display: flex;
		flex-direction: column;
		.wpb_column:first-child{
			order: 2;
		}
	}

	@media only (--not_phone){
		.wpb_column:first-child{
			padding-left: 10%;
	    	margin-top: 150px;
		}
	}
}

.product4{
	margin-top: 70px;

	.wpb_column:nth-child(2){

		@media only (--not_phone){
			margin-top: 170px;
		}
	}

}

.product5{
	h2.vc_custom_heading.custom-title3{
		margin-top: 20px;
	}
}

.product6{
	margin-top: 70px;

	@media only (--not_phone){
		margin-bottom: -150px;	
	}

}

.product6-img{
	.wpb_column:first-child{
		@media only (--not_phone){
			margin-top: 180px;
		}
		position: relative;
		z-index: 9;
	}

	.wpb_column:last-child{

		@media only (--not_phone){
			margin-left: -80px;
		}
	}

	
	.wpb_column{
		@media only (--portable){
			width: 50%;
			display: inline-block;
		}
	}
	
}

.product7{
	margin-top: 70px;

	.wpb_column:nth-child(2){

		@media only (--not_phone){
			margin-top: 100px;
	    	padding-left: 50px
	    }
	}


}

.product7-img{
	margin-top: 20px;
	max-width: 950px;
	margin-bottom: 70px;

	img{
		max-height: 500px;
		width: auto;
	}
}